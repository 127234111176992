import { matchPath, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();

  // NOTE Rotra-specific hack to handle the dashboard path
  let trimmedPath = path;
  if (path === '/dashboard') {
    trimmedPath = path;
  } else if (path.startsWith('/dashboard')) {
    trimmedPath = path.slice(10);
  }

  let trimmedPathname = pathname;
  if (pathname === '/dashboard') {
    trimmedPathname = pathname;
  } else if (pathname.startsWith('/dashboard')) {
    trimmedPathname = pathname.slice(10);
  }

  // NOTE original code
  // const normalActive = trimmedPath
  //   ? !!matchPath({ path: trimmedPath, end: true }, trimmedPathname)
  //   : false;

  const deepActive = trimmedPath
    ? !!matchPath({ path: trimmedPath, end: false }, trimmedPathname)
    : false;

  // NOTE original code
  // return deep ? deepActive : normalActive;

  // NOTE Rotra-specific hack to handle the dashboard path
  return deepActive;
}
